.ck-editor h1 {
    font-size: 2em;
    margin: 0.67em 0;
  }
  
  .ck-editor h2 {
    font-size: 1.5em;
    margin: 0.83em 0;
  }
  
  .ck-editor h3 {
    font-size: 1.17em;
    margin: 1em 0;
  }
  
  .ck-editor h4 {
    margin: 1.33em 0;
  }
  
  .ck-editor h5 {
    font-size: 0.83em;
    margin: 1.67em 0;
  }
  
  .ck-editor h6 {
    font-size: 0.67em;
    margin: 2.33em 0;
  }
  
  .ck-editor p {
    margin: 1em 0;
  }
  
  .ck-editor ul {
    list-style-type: disc;
    padding-left: 40px;
  }
  
  .ck-editor li {
    margin: 0.5em 0;
  }
  
  .ck-editor strong,
  .ck-editor b {
    font-weight: bold;
  }
  
  .ck-editor hr {
    border: none;
    border-top: 1px solid #000;
  }
  
  .ck-editor html {
    display: block;
    margin: 8px;
  }
  